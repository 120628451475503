<template>
  <header class="main-header d-flex justify-content-between">
    <div class="main-header__left d-flex flex-column justify-content-center">
      <h1>{{ title }}</h1>
      <p>{{ $route.meta.description ? $route.meta.description : "" }}</p>
    </div>
    <div class="main-header__right d-flex align-items-center">
      <b-badge
        class="d-flex align-items-center justify-content-center mr-3 m-cursor-pointer"
        variant="dark-blue"
      >
        <NotificationIcon />
      </b-badge>
      <b-badge
        class="d-flex align-items-center justify-content-center mr-2 m-cursor-pointer"
        variant="light-blue"
      >
        AA
      </b-badge>
      <span class="d-none d-md-block">
        <span class="main-header__user-name d-block">{{ user.name }}</span>
        <span class="main-header__email d-block">{{ user.email }}</span>
      </span>
    </div>
  </header>
</template>
<script>
import NotificationIcon from "../components/svg/NotificationIcon.vue";
export default {
  components: {
    NotificationIcon,
  },
  data() {
    return {
      user: {
        name: "Asiyah Bidemi",
        email: "asiyah@gmail.com",
      },
    };
  },
  computed: {
    title() {
      const type = this.$route.query?.type;
      const prefix =
        this.$route.meta?.titleFromType && !!this.$route.query?.type
          ? type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()
          : "";
      const title = this.$route.meta.title ? this.$route.meta.title : "";

      return `${prefix} ${title}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-header {
  background-color: $body-bg;
  color: $dark-blue;
  height: 131px;
  padding: 2.65% 3.8% 0.2% 3.8%;
  h1 {
    font-size: 1.875rem;
    font-weight: 600;
    @include media-breakpoint-down(sm) {
      font-size: 1.25rem;
    }
  }
  p {
    @include media-breakpoint-down(sm) {
      font-size: 0.875rem;
    }
  }
  &__right {
    .badge {
      padding: 0.6em 0.6em;
      height: 32px;
      width: 32px;
    }
    .badge-light-blue {
      color: $tertiary-color;
    }
  }
  &__user-name {
    font-weight: 600;
  }
  &__email {
    color: $faint-blue;
    font-size: 0.875rem;
  }
}
</style>
