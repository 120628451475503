<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M14.4667 19.25H16.0417C20.069 19.25 23.3333 15.9857 23.3333 11.9583C23.3333 7.931 20.069 4.66667 16.0417 4.66667H11.9583C7.931 4.66667 4.66667 7.931 4.66667 11.9583C4.66667 16.66 7.34767 19.4717 10.1675 21.1668C11.5827 22.0173 13.0083 22.5668 14.0852 22.9034C14.2182 22.9448 14.3459 22.9833 14.4667 23.0183V19.25ZM15.6333 24.5C15.6333 24.5 15.1877 24.4247 14.4667 24.2299C11.4275 23.4062 3.5 20.4458 3.5 11.9583C3.5 7.287 7.287 3.5 11.9583 3.5H16.0417C20.713 3.5 24.5 7.287 24.5 11.9583C24.5 16.6297 20.713 20.4167 16.0417 20.4167H15.6333V24.5Z"
          fill="white"
    />
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M16.1531 7.28117C16.7539 7.68017 17.2066 8.32708 17.2066 9.1945C17.2066 10.115 16.8356 10.7917 16.2342 11.2058C15.9968 11.3666 15.7347 11.4876 15.4584 11.564V12.4577C15.4584 12.6897 15.3662 12.9123 15.2021 13.0764C15.038 13.2405 14.8154 13.3327 14.5834 13.3327C14.3513 13.3327 14.1287 13.2405 13.9646 13.0764C13.8005 12.9123 13.7084 12.6897 13.7084 12.4577V10.8261C13.7083 10.6041 13.7926 10.3904 13.9443 10.2282C14.0959 10.0661 14.3035 9.96763 14.525 9.95283C14.8482 9.93125 15.0932 9.86708 15.2408 9.765C15.3006 9.72537 15.3489 9.67064 15.3808 9.60633C15.4158 9.53633 15.4566 9.4115 15.4566 9.19392C15.4566 9.01133 15.3854 8.87192 15.1842 8.73775C14.9555 8.58608 14.588 8.47408 14.1464 8.46008C13.7101 8.44608 13.286 8.53125 12.9739 8.68233C12.6613 8.834 12.5464 9.00433 12.5143 9.12975C12.4857 9.24106 12.4355 9.34564 12.3665 9.43754C12.2975 9.52944 12.2111 9.60685 12.1122 9.66535C12.0133 9.72385 11.9038 9.7623 11.79 9.7785C11.6763 9.79469 11.5604 9.78832 11.4491 9.75975C11.3378 9.73118 11.2332 9.68096 11.1413 9.61197C11.0494 9.54297 10.972 9.45655 10.9135 9.35764C10.855 9.25873 10.8166 9.14927 10.8004 9.0355C10.7842 8.92173 10.7905 8.80589 10.8191 8.69458C11.0204 7.91233 11.6054 7.40133 12.2109 7.10792C12.8176 6.81333 13.531 6.68908 14.2019 6.71067C14.8686 6.73167 15.5803 6.89967 16.1531 7.28117Z"
          fill="white"
    />
    <path
      d="M15.75 15.75C15.75 16.0594 15.6271 16.3562 15.4083 16.575C15.1895 16.7937 14.8928 16.9167 14.5834 16.9167C14.2739 16.9167 13.9772 16.7937 13.7584 16.575C13.5396 16.3562 13.4167 16.0594 13.4167 15.75C13.4167 15.4406 13.5396 15.1438 13.7584 14.925C13.9772 14.7062 14.2739 14.5833 14.5834 14.5833C14.8928 14.5833 15.1895 14.7062 15.4083 14.925C15.6271 15.1438 15.75 15.4406 15.75 15.75Z"
      fill="white"
    />
  </svg>
</template>
<script>
 export default{
    name: "SupportIcon" 
  }
</script>