<template>
  <svg width="28" height="14" viewBox="0 0 28 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.818 2.03418C27.9846 2.39398 27.9958 3.84018 20.9804 7.13298L14.1022 10.3614C11.7082 11.4856 8.23479 12.964 6.70319 13.3728C5.63079 13.6584 4.60739 12.9304 4.60739 12.9304C4.60739 12.9304 1.51899 10.1654 0.698587 9.54378C-0.120413 8.92218 -0.0504134 8.60578 0.999587 8.26978C1.65339 8.06118 5.38159 8.95998 6.97059 9.36178C8.41968 8.49387 9.88577 7.65464 11.368 6.84458C9.41359 5.39838 5.75679 2.69778 4.59059 1.87458C3.47199 1.08358 4.60459 0.799382 4.60459 0.799382C5.11979 0.660782 6.23139 0.480182 6.79139 0.597782C10.6008 1.39438 16.9596 3.49718 17.4468 3.65818C18.7979 2.94998 20.1618 2.2662 21.5376 1.60718C24.4426 0.243581 27.5954 1.55538 27.818 2.03418Z"
      fill="white"
    />
  </svg>
</template>
  <script>
  export default {
      name: "VacationIcon"
  }
  </script>